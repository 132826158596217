import React from 'react';
import './variables.css';
import './global.css';
import Seo from './SeoComponent';
import Footer from './FooterNew';
import { Container, Grid } from '@mui/material';
import Mainnavigation from './NavigationNew';
import AdBlock from './AdBlock';
import sanitizeHtml from 'sanitize-html';
import { Link } from 'gatsby';
import * as styles from './navigationHeader.module.css';
// import FollowUs from '../templates/FollowUs';

const PhoneComponent = ({
    postTitle = '',
    brandName = 'Apple',
    adBlockDynamicPrice = '0.0',
    adBlockPromoText = '',
    adBlockDescription = '',
    activeTab = '',
    subcomponent = <></>,
    canonicalUrl = '',
}) => {
    postTitle = sanitizeHtml(postTitle);
    brandName = sanitizeHtml(brandName);
    adBlockDynamicPrice = sanitizeHtml(adBlockDynamicPrice);
    adBlockPromoText = adBlockPromoText;
    adBlockDescription = adBlockDescription;

    // const domainUrl = process.env.GATSBY_DOMAIN_URL || '';
    const domainUrl = '';
    const postTitleUrl = postTitle.replaceAll(' ', '-');
    const reviewUrl = `${domainUrl}/supported-phones/${brandName}/${postTitleUrl}/Review`;
    const repairUrl = `${domainUrl}/supported-phones/${brandName}/${postTitleUrl}/Screen-Repair`;
    const specsUrl = `${domainUrl}/supported-phones/${brandName}/${postTitleUrl}/Specifications`;
    const articleUrl = `${domainUrl}/supported-phones/${brandName}/${postTitleUrl}/Articles`;

    return (
        <>
            <Seo />
            <Mainnavigation />
            <main>
                <Container ssm sx={{ p: { xs: 0, md: '1rem' } }}>
                    <Grid xs={12} md={8} sx={{ mr: 'auto', ml: 'auto', maxWidth: { xs: 'unset', md: '768px' } }}>
                        <AdBlock
                            modelName={postTitle}
                            brandName={brandName}
                            adBlockDynamicPrice={adBlockDynamicPrice}
                            adBlockPromoText={adBlockPromoText}
                            adBlockDescription={adBlockDescription}
                            isSecondaryBlock="true"
                        />
                    </Grid>

                    <Grid xs={12} sx={{ mt: 2 }} display="flex" justifyContent="center">
                        <h1 style={{ fontSize: '22px', fontWeight: '600', marginTop: '24px', textAlign: 'center' }}>
                            {postTitle}
                        </h1>
                    </Grid>

                    <Grid sx={{ mt: 2, mb: 2, ml: 'auto', mr: 'auto' }} width={{ md: '60%' }}>
                        <nav
                            role="navigation"
                            className={styles.container}
                            aria-label="Main"
                            style={{ padding: '0px', fontWeight: '600', fontSize: '14px' }}
                        >
                            <ul className={styles.navigationTabs}>
                                <li className={styles.navigationTabItem} style={{}}>
                                    <Link
                                        id={'btn-supp-phone-review'}
                                        to={reviewUrl}
                                        activeClassName="active"
                                        activeStyle={{}}
                                        className={`${activeTab === 'review' ? 'active' : ''}`}
                                    >
                                        Review
                                    </Link>
                                </li>
                                <li className={styles.navigationTabItem}>
                                    <Link
                                        id={'btn-supp-phone-specs'}
                                        to={specsUrl}
                                        activeClassName="active"
                                        activeStyle={{}}
                                        className={`${activeTab === 'specs' ? 'active' : ''}`}
                                    >
                                        Specs
                                    </Link>
                                </li>
                                <li className={styles.navigationTabItem} style={{}}>
                                    <Link
                                        id={'btn-supp-phone-repairs'}
                                        to={repairUrl}
                                        activeClassName="active"
                                        activeStyle={{}}
                                        className={`${activeTab === 'repair' ? 'active' : ''}`}
                                    >
                                        Repairs
                                    </Link>
                                </li>
                                <li className={styles.navigationTabItem} style={{}}>
                                    <Link
                                        id={'btn-supp-phone-articles'}
                                        to={articleUrl}
                                        activeClassName="active"
                                        activeStyle={{}}
                                        className={`${activeTab === 'article' ? 'active' : ''}`}
                                    >
                                        Articles
                                    </Link>
                                </li>
                            </ul>
                        </nav>
                    </Grid>

                    <Grid xs={12}>{subcomponent}</Grid>

                    <Grid xs={12} md={8} sx={{ mt: 5, mr: 'auto', ml: 'auto', maxWidth: { xs: 'unset', md: '768px' } }}>
                        <AdBlock
                            modelName={postTitle}
                            brandName={brandName}
                            adBlockDynamicPrice={adBlockDynamicPrice}
                            adBlockPromoText={adBlockPromoText}
                            adBlockDescription={adBlockDescription}
                            isSecondaryBlock="true"
                            canonicalUrl={canonicalUrl}
                        />
                    </Grid>
                </Container>
            </main>
            {/* <FollowUs /> */}
            <Footer />
        </>
    );
};

export default PhoneComponent;
