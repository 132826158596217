import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import Seo from '../components/SeoComponent';
import PhoneComponent from '../components/PhoneComponent';
import { Box, Grid, Container } from '@mui/material';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { Link } from 'gatsby';
import MiniAdTemplate from './MiniAdTemplate';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import sanitizeHtml from 'sanitize-html';

const Bold = ({ children }) => <span className="bold">{children}</span>;
const Text = ({ children }) => <p className="align-center">{children}</p>;

// const domainUrl = process.env.GATSBY_DOMAIN_URL || '';
const domainUrl = '';

const options = {
    renderMark: {
        [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
    },
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
        [BLOCKS.EMBEDDED_ASSET]: (node) => {
            return (
                <>
                    <h2>Embedded Asset</h2>
                    <pre>
                        <code>{JSON.stringify(node, null, 2)}</code>
                    </pre>
                </>
            );
        },
    },
};

class ArticleTemplate extends React.Component {
    render() {
        const post = get(this.props, 'data.DeviceData');
        var articleData = '';
        let metaTags = '';
        const postBrandName = post && post.brandName ? sanitizeHtml(post.brandName) : '';

        if (postBrandName === 'Apple') {
            articleData = get(this.props, 'data.appleArticleData');
            metaTags = get(this.props, 'data.metaTagsApple');
        } else {
            articleData = get(this.props, 'data.samsungArticleData');
            metaTags = get(this.props, 'data.metaTagsSamsung');
        }
        const commonArticleData = get(this.props, 'data.commonArticleData');

        const image = getImage(articleData.nodes[0].articleImage);

        const postTitle = post && post.title ? sanitizeHtml(post.title) : '';
        const postPrice = post && post.adBlockDynamicPrice ? sanitizeHtml(post.adBlockDynamicPrice) : '';
        const postPromoText = post && post.adBlockPromoText ? post.adBlockPromoText : '';
        const postDescription = post && post.adBlockDescription ? post.adBlockDescription : '';
        const metaTitle = post.title + ' | Articles | Asurion Mobile+';
        const metaDescription = metaTags && metaTags.description ? metaTags.description : '';
        const ogImage = metaTags && metaTags.ogImage ? metaTags.ogImage.file.url : '';

        const canonicalUrl =
            this.props && this.props.pageContext && this.props.pageContext.canonicalPath
                ? domainUrl + this.props.pageContext.canonicalPath
                : domainUrl + this.props.path;

        console.log('canonical', canonicalUrl);

        return (
            <PhoneComponent
                postTitle={postTitle}
                brandName={postBrandName}
                adBlockDynamicPrice={postPrice}
                adBlockPromoText={postPromoText}
                adBlockDescription={postDescription}
                activeTab={'article'}
                canonicalUrl={canonicalUrl}
                subcomponent={
                    <>
                        <Seo
                            title={metaTitle}
                            description={metaDescription}
                            ogImage={ogImage}
                            image={ogImage}
                            canonical={canonicalUrl}
                        />

                        <Container maxWidth="ssm" fixed className="ltArticleContainer">
                            <Box sx={{ width: '100%' }}>
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    style={{ margin: 'auto', borderRadius: '16px' }}
                                >
                                    <Grid
                                        item
                                        sx={{ flexGrow: 1 }}
                                        style={{ backgroundColor: '#F0F0F5', borderRadius: '16px', marginBottom: '3%' }}
                                    >
                                        {articleData.nodes[0].articleImage.file.url && (
                                            <div>
                                                <GatsbyImage
                                                    image={image}
                                                    style={{ borderRadius: '16px 16px 0 0', marginBottom: '2%' }}
                                                    alt={articleData.nodes[0].articleTitle}
                                                />
                                                <div style={{ borderRadius: '16px' }}>
                                                    <Box
                                                        sx={{ fontWeight: 'bold', padding: '0 3%', fontSize: '18px' }}
                                                        pt="3px"
                                                    >
                                                        {articleData.nodes[0].articleTitle}
                                                    </Box>
                                                    <Box sx={{ fontSize: '16px', padding: '0 3%' }}>
                                                        {articleData.nodes[0].articleShortDescription &&
                                                            renderRichText(
                                                                articleData.nodes[0].articleShortDescription,
                                                                options
                                                            )}
                                                    </Box>
                                                    <Box sx={{ fontSize: '14px', padding: '0 3% 3%' }}>
                                                        <Link
                                                            className="linkArticle"
                                                            to={
                                                                domainUrl +
                                                                `/${post?.brandName}/${post.title
                                                                    .replaceAll(' ', '-')
                                                                    .replaceAll(
                                                                        ':',
                                                                        ''
                                                                    )}/Articles/${articleData.nodes[0].articleTitle
                                                                    .replaceAll(' ', '-')
                                                                    .replace(/[?=]/g, '')
                                                                    .replaceAll(':', '')}`
                                                            }
                                                        >
                                                            Read More
                                                        </Link>
                                                    </Box>
                                                </div>
                                            </div>
                                        )}
                                    </Grid>
                                    <Grid>
                                        <MiniAdTemplate>
                                            As an Asurion Mobile+ member you get access to hundreds of articles, videos
                                            and virtual / personal assistance.{' '}
                                            <a href="https://www.asurion.com.au" className="linkArticle">
                                                Find out more
                                            </a>
                                        </MiniAdTemplate>
                                    </Grid>
                                    <Grid>
                                        <Box sx={{ fontWeight: 'bold', padding: '3% 0', fontSize: '1.25rem' }}>
                                            Recommended articles for you
                                        </Box>
                                        {articleData.nodes[1] && (
                                            <Container style={{ paddingLeft: '0', paddingRight: '0' }}>
                                                <Box
                                                    display="grid"
                                                    gridTemplateColumns="repeat(2, 1fr)"
                                                    gap={2}
                                                    width={{ xs: 1, md: '60%' }}
                                                    mt={2}
                                                    mx="auto"
                                                >
                                                    <ArticleTilesRecommended
                                                        postData={post}
                                                        articleHeading={articleData.nodes[1].articleTitle}
                                                        articleImage={articleData.nodes[1].articleImage}
                                                        articleShortDescription={
                                                            articleData.nodes[1].articleShortDescription
                                                        }
                                                        index={1}
                                                    />
                                                    <ArticleTilesRecommended
                                                        postData={post}
                                                        articleHeading={articleData.nodes[2].articleTitle}
                                                        articleImage={articleData.nodes[2].articleImage}
                                                        articleShortDescription={
                                                            articleData.nodes[2].articleShortDescription
                                                        }
                                                        index={2}
                                                    />
                                                </Box>
                                            </Container>
                                        )}
                                        {articleData.nodes.slice(3).map((nodes, index) => (
                                            <Grid
                                                item
                                                sx={{ flexGrow: 1 }}
                                                mt={'10%'}
                                                style={{ backgroundColor: '#F0F0F5', borderRadius: '16px' }}
                                            >
                                                {articleData.nodes[index + 3].articleImage.file.url && (
                                                    <div>
                                                        <GatsbyImage
                                                            image={getImage(articleData.nodes[index + 3].articleImage)}
                                                            style={{
                                                                borderRadius: '16px 16px 0 0',
                                                                paddingBottom: '3%',
                                                            }}
                                                            alt={articleData.nodes[index + 3].articleTitle}
                                                        />

                                                        <div style={{ borderRadius: '16px' }}>
                                                            <Box
                                                                sx={{
                                                                    fontWeight: 'bold',
                                                                    padding: '3% 3% 0',
                                                                    fontSize: '18px',
                                                                }}
                                                                pt="3px"
                                                            >
                                                                {articleData.nodes[index + 3].articleTitle}
                                                            </Box>
                                                            <Box sx={{ fontSize: '16px', padding: '0 3%' }}>
                                                                {articleData.nodes[index + 3].articleShortDescription &&
                                                                    renderRichText(
                                                                        articleData.nodes[index + 3]
                                                                            .articleShortDescription,
                                                                        options
                                                                    )}
                                                            </Box>
                                                            <Box sx={{ fontSize: '14px', padding: '0 3% 3%' }}>
                                                                <Link
                                                                    to={
                                                                        domainUrl +
                                                                        `/${post?.brandName}/${post.title
                                                                            .replaceAll(' ', '-')
                                                                            .replaceAll(
                                                                                ':',
                                                                                ''
                                                                            )}/Articles/${articleData.nodes[
                                                                            index + 3
                                                                        ].articleTitle
                                                                            .replaceAll(' ', '-')
                                                                            .replace(/[?=]/g, '')
                                                                            .replaceAll(':', '')}`
                                                                    }
                                                                    className="linkArticle"
                                                                >
                                                                    Read More
                                                                </Link>
                                                            </Box>
                                                        </div>
                                                    </div>
                                                )}
                                            </Grid>
                                        ))}
                                        {commonArticleData.nodes.map((nodes, index) => (
                                            <Grid
                                                item
                                                sx={{ flexGrow: 1 }}
                                                mt={'10%'}
                                                style={{ backgroundColor: '#F0F0F5', borderRadius: '16px' }}
                                            >
                                                {commonArticleData.nodes[index].articleImage.file.url && (
                                                    <div>
                                                        <GatsbyImage
                                                            image={getImage(
                                                                commonArticleData.nodes[index].articleImage
                                                            )}
                                                            style={{
                                                                borderRadius: '16px 16px 0 0',
                                                                paddingBottom: '3%',
                                                            }}
                                                            alt={commonArticleData.nodes[index].articleTitle}
                                                        />

                                                        <div style={{ borderRadius: '16px' }}>
                                                            <Box
                                                                sx={{
                                                                    fontWeight: 'bold',
                                                                    padding: '3% 3% 0',
                                                                    fontSize: '18px',
                                                                }}
                                                                pt="3px"
                                                            >
                                                                {commonArticleData.nodes[index].articleTitle}
                                                            </Box>
                                                            <Box sx={{ fontSize: '16px', padding: '0 3%' }}>
                                                                {commonArticleData.nodes[index]
                                                                    .articleShortDescription &&
                                                                    renderRichText(
                                                                        commonArticleData.nodes[index]
                                                                            .articleShortDescription,
                                                                        options
                                                                    )}
                                                            </Box>
                                                            <Box sx={{ fontSize: '14px', padding: '0 3% 3%' }}>
                                                                <Link
                                                                    to={
                                                                        domainUrl +
                                                                        `/${post?.brandName}/${post.title
                                                                            .replaceAll(' ', '-')
                                                                            .replaceAll(
                                                                                ':',
                                                                                ''
                                                                            )}/Articles/${commonArticleData.nodes[
                                                                            index
                                                                        ].articleTitle
                                                                            .replaceAll(' ', '-')
                                                                            .replace(/[?=]/g, '')
                                                                            .replaceAll(':', '')}`
                                                                    }
                                                                    className="linkArticle"
                                                                >
                                                                    Read More
                                                                </Link>
                                                            </Box>
                                                        </div>
                                                    </div>
                                                )}
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                            </Box>
                        </Container>
                    </>
                }
            />
        );
    }
}
const ArticleTilesRecommended = ({
    articleHeading,
    articleImage,
    articleShortDescription,
    item,
    onClick,
    className,
    postData,
}) => {
    return (
        <>
            {articleImage ? (
                <Grid gridColumn="span 1">
                    <Box
                        sx={{ backgroundColor: '#F0F0F5', borderRadius: '16px', px: '0' }}
                        key={item}
                        onClick={onClick}
                        className={className}
                    >
                        {/* <img
                            src={articleImage}
                            alt={articleHeading}
                            style={{ borderRadius: '16px 16px 0px 0px' }}
                            className="img"
                        /> */}
                        <GatsbyImage
                            image={getImage(articleImage)}
                            style={{
                                borderRadius: '16px 16px 0 0',
                                paddingBottom: '3%',
                            }}
                            alt={articleHeading}
                        />
                        <Box
                            sx={{
                                padding: '14px 10px 16px 10px',
                                borderRadius: '16px 16px 0px 0px',
                            }}
                        >
                            <Box
                                component="p"
                                sx={{
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                    minHeight: '4.5em',
                                    lineHeight: '18px',
                                    margin: '0px',
                                }}
                                style={{ marginBottom: '10px' }}
                            >
                                {articleHeading}
                            </Box>
                            <Box sx={{ fontSize: '16px', padding: '0 3%' }} className="shrtDesc">
                                {articleShortDescription && renderRichText(articleShortDescription, options)}
                                ...
                            </Box>
                            <Link
                                to={
                                    domainUrl +
                                    `/${postData?.brandName}/${postData.title
                                        .replaceAll(' ', '-')
                                        .replaceAll(':', '')}/Articles/${articleHeading
                                        .replaceAll(' ', '-')
                                        .replace(/[?=]/g, '')
                                        .replaceAll(':', '')}`
                                }
                                className="linkArticle"
                            >
                                Read more
                            </Link>
                        </Box>
                    </Box>
                </Grid>
            ) : (
                ''
            )}
        </>
    );
};

export default ArticleTemplate;

export const pageQuery1 = graphql`
    query articleQuery($slug: String) {
        DeviceData: contentfulDeviceDetailPage(title: { eq: $slug }) {
            brand
            brandName
            title
            adBlockDynamicPrice
            adBlockPromoText {
                raw
            }
            adBlockDescription {
                raw
            }
        }
        appleArticleData: allContentfulArticleTemplateApple(filter: { node_locale: { eq: "en-US" } }) {
            nodes {
                articleTitle
                deviceTitle
                articleShortDescription {
                    raw
                }
                headerImage {
                    file {
                        url
                    }
                    gatsbyImageData(height: 400, layout: CONSTRAINED, width: 700, resizingBehavior: SCALE)
                }
                publishedDate
                articleDetail1 {
                    raw
                }
                articleDetail2 {
                    raw
                }
                articleImage {
                    file {
                        url
                    }
                    gatsbyImageData(height: 400, layout: CONSTRAINED, width: 700, resizingBehavior: SCALE)
                }
            }
        }
        samsungArticleData: allContentfulArticleTemplateSamsung(filter: { node_locale: { eq: "en-US" } }) {
            nodes {
                articleTitle
                deviceTitle
                articleShortDescription {
                    raw
                }
                headerImage {
                    file {
                        url
                    }
                    gatsbyImageData(height: 400, layout: CONSTRAINED, width: 700, resizingBehavior: SCALE)
                }
                publishedDate
                articleDetail1 {
                    raw
                }
                articleDetail2 {
                    raw
                }
                articleImage {
                    file {
                        url
                    }
                    gatsbyImageData(height: 400, layout: CONSTRAINED, width: 700, resizingBehavior: SCALE)
                }
            }
        }
        metaTagsApple: contentfulMetaTags(title: { eq: "Apple Articles" }) {
            title
            robots
            canonical
            description
            ogImage {
                file {
                    url
                    fileName
                }
            }
        }
        metaTagsSamsung: contentfulMetaTags(title: { eq: "Samsung Articles" }) {
            title
            robots
            canonical
            description
            ogImage {
                file {
                    url
                    fileName
                }
            }
        }
        commonArticleData: allContentfulArticleTemplateCommon(filter: { node_locale: { eq: "en-US" } }) {
            nodes {
                articleTitle
                deviceTitle
                articleShortDescription {
                    raw
                }
                headerImage {
                    file {
                        url
                    }
                    gatsbyImageData(height: 400, layout: CONSTRAINED, width: 700, resizingBehavior: SCALE)
                }
                publishedDate
                articleDetail1 {
                    raw
                }
                articleDetail2 {
                    raw
                }
                articleImage {
                    file {
                        url
                    }
                    gatsbyImageData(height: 400, layout: CONSTRAINED, width: 700, resizingBehavior: SCALE)
                }
            }
        }
    }
`;
